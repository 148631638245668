export default [
  {
    title: 'Team',
    icon: 'HardDriveIcon',
    customicon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="#333333" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.9998 7.90977C17.9698 7.90977 17.9498 7.90977 17.9198 7.90977H17.8698C15.9798 7.84977 14.5698 6.38977 14.5698 4.58977C14.5698 2.74977 16.0698 1.25977 17.8998 1.25977C19.7298 1.25977 21.2298 2.75977 21.2298 4.58977C21.2198 6.39977 19.8098 7.85977 18.0098 7.91977C18.0098 7.90977 18.0098 7.90977 17.9998 7.90977ZM17.8998 2.74977C16.8898 2.74977 16.0698 3.56977 16.0698 4.57977C16.0698 5.56977 16.8398 6.36977 17.8298 6.40977C17.8398 6.39977 17.9198 6.39977 18.0098 6.40977C18.9798 6.35977 19.7298 5.55977 19.7398 4.57977C19.7398 3.56977 18.9198 2.74977 17.8998 2.74977Z" fill="#333333"/>
    <path d="M18.01 15.2801C17.62 15.2801 17.23 15.2501 16.84 15.1801C16.43 15.1101 16.16 14.7201 16.23 14.3101C16.3 13.9001 16.69 13.6301 17.1 13.7001C18.33 13.9101 19.63 13.6802 20.5 13.1002C20.97 12.7902 21.22 12.4001 21.22 12.0101C21.22 11.6201 20.96 11.2401 20.5 10.9301C19.63 10.3501 18.31 10.1201 17.07 10.3401C16.66 10.4201 16.27 10.1401 16.2 9.73015C16.13 9.32015 16.4 8.93015 16.81 8.86015C18.44 8.57015 20.13 8.88014 21.33 9.68014C22.21 10.2701 22.72 11.1101 22.72 12.0101C22.72 12.9001 22.22 13.7502 21.33 14.3502C20.42 14.9502 19.24 15.2801 18.01 15.2801Z" fill="#333333"/>
    <path d="M5.96998 7.91C5.95998 7.91 5.94998 7.91 5.94998 7.91C4.14998 7.85 2.73998 6.39 2.72998 4.59C2.72998 2.75 4.22998 1.25 6.05998 1.25C7.88998 1.25 9.38998 2.75 9.38998 4.58C9.38998 6.39 7.97998 7.85 6.17998 7.91L5.96998 7.16L6.03998 7.91C6.01998 7.91 5.98998 7.91 5.96998 7.91ZM6.06998 6.41C6.12998 6.41 6.17998 6.41 6.23998 6.42C7.12998 6.38 7.90998 5.58 7.90998 4.59C7.90998 3.58 7.08998 2.75999 6.07998 2.75999C5.06998 2.75999 4.24998 3.58 4.24998 4.59C4.24998 5.57 5.00998 6.36 5.97998 6.42C5.98998 6.41 6.02998 6.41 6.06998 6.41Z" fill="#333333"/>
    <path d="M5.96 15.2801C4.73 15.2801 3.55 14.9502 2.64 14.3502C1.76 13.7602 1.25 12.9101 1.25 12.0101C1.25 11.1201 1.76 10.2701 2.64 9.68014C3.84 8.88014 5.53 8.57015 7.16 8.86015C7.57 8.93015 7.84 9.32015 7.77 9.73015C7.7 10.1401 7.31 10.4201 6.9 10.3401C5.66 10.1201 4.35 10.3501 3.47 10.9301C3 11.2401 2.75 11.6201 2.75 12.0101C2.75 12.4001 3.01 12.7902 3.47 13.1002C4.34 13.6802 5.64 13.9101 6.87 13.7001C7.28 13.6301 7.67 13.9101 7.74 14.3101C7.81 14.7201 7.54 15.1101 7.13 15.1801C6.74 15.2501 6.35 15.2801 5.96 15.2801Z" fill="#333333"/>
    <path d="M11.9998 15.38C11.9698 15.38 11.9498 15.38 11.9198 15.38H11.8698C9.97982 15.32 8.56982 13.86 8.56982 12.06C8.56982 10.22 10.0698 8.72998 11.8998 8.72998C13.7298 8.72998 15.2298 10.23 15.2298 12.06C15.2198 13.87 13.8098 15.33 12.0098 15.39C12.0098 15.38 12.0098 15.38 11.9998 15.38ZM11.8998 10.22C10.8898 10.22 10.0698 11.04 10.0698 12.05C10.0698 13.04 10.8398 13.84 11.8298 13.88C11.8398 13.87 11.9198 13.87 12.0098 13.88C12.9798 13.83 13.7298 13.03 13.7398 12.05C13.7398 11.05 12.9198 10.22 11.8998 10.22Z" fill="#333333"/>
    <path d="M11.9998 22.7597C10.7998 22.7597 9.59978 22.4497 8.66978 21.8197C7.78978 21.2297 7.27979 20.3897 7.27979 19.4897C7.27979 18.5997 7.77978 17.7397 8.66978 17.1497C10.5398 15.9097 13.4698 15.9097 15.3298 17.1497C16.2098 17.7397 16.7198 18.5797 16.7198 19.4797C16.7198 20.3697 16.2198 21.2297 15.3298 21.8197C14.3998 22.4397 13.1998 22.7597 11.9998 22.7597ZM9.49979 18.4097C9.02979 18.7197 8.77979 19.1097 8.77979 19.4997C8.77979 19.8897 9.03979 20.2697 9.49979 20.5797C10.8498 21.4897 13.1398 21.4897 14.4898 20.5797C14.9598 20.2697 15.2098 19.8797 15.2098 19.4897C15.2098 19.0997 14.9498 18.7197 14.4898 18.4097C13.1498 17.4997 10.8598 17.5097 9.49979 18.4097Z" fill="#333333"/>
    </svg>`,
    route: 'team',
    resource: 'Setting Access Account',
    action: 'read',
  },
]
